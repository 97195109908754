import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import Img from "gatsby-image";
import styled from "styled-components";
import SEO from "../components/SEO";
import createImageAlt from "../utils/createImageAlt";

const RealisatiePageStyles = styled.section`
  width: 100%;
  text-align: center;
  padding: 50px 15px;
`;

const RealisatieGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 15px;
  margin-top: 30px;

  @media (min-width: 440px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 960px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const description = "Elektriciteitswerken door Stetrics Itegem. Bekijk hier enkele van onze realisaties in gedane elektriciteitswerken en elektrische aansluitingen";

const RealisatiePage = ({ location }) => {
  const { images } = useStaticQuery(graphql`
    query {
      images: allImageSharp {
        nodes {
          fluid(maxWidth: 1000, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <RealisatiePageStyles>
      <SEO title='Realisaties | Stetrics algemene elektriciteitswerken' location={location} description={description} />
      <h1>Realisaties</h1>
      <RealisatieGrid>
        {images.nodes.map((image) => {
          const filename = createImageAlt(image.fluid);
          return <Img key={filename} alt={filename} fluid={image.fluid} />;
        })}
      </RealisatieGrid>
    </RealisatiePageStyles>
  );
};

export default RealisatiePage;
